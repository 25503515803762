import { getInvalidEmailError } from '../Fields/EmailField.js';
import { getInvalidNameError } from '../Fields/NameField.js';

/**
 * Validates if a value is empty or only whitespace.
 *
 * @param value - The value to check
 * @returns Returns 'Required' if value is empty or only whitespace, undefined otherwise
 */
function getRequiredError(value) {
  if (!value || value.trim() === '') {
    return 'Required';
  }
}

/**
 * Validates if a phone number contains exactly 10 digits.
 * Strips all non-digit characters before validation.
 *
 * @param value - The phone number to validate
 * @returns Returns 'Must be 10 digits' if invalid, undefined otherwise
 */
function getInvalidPhoneError(value) {
  var digits = value.replace(/\D/g, '');
  if (digits.length !== 10) {
    return 'Must be 10 digits';
  }
}

/**
 * Validates a full name by checking if it's empty and contains both first and last name.
 * Uses both required field validation and name-specific validation.
 *
 * @param value - The full name to validate
 * @returns Returns error message if invalid, undefined otherwise
 */
function getNameError(value) {
  // Check for required field and invalid characters first
  var requiredError = getRequiredError(value);
  var invalidNameError = getInvalidNameError(value);
  if (requiredError || invalidNameError) {
    return 'Please provide your first and last name';
  }

  // Check if there are at least two name parts (first and last name)
  var nameParts = value.split(' ').filter(function (part) {
    return part.length > 0;
  });
  if (nameParts.length < 2) {
    return 'Please provide your first and last name';
  }
}

/**
 * Validates an email address by checking if it's empty and properly formatted.
 * Uses both required field validation and email-specific validation.
 *
 * @param value - The email address to validate
 * @returns Returns error message if invalid, undefined otherwise
 */
function getEmailError(value) {
  if (getRequiredError(value) || getInvalidEmailError(value)) {
    return 'Please provide a valid email address';
  }
}

/**
 * Validates a phone number by checking if it's empty and properly formatted.
 * Uses both required field validation and phone-specific validation.
 *
 * @param value - The phone number to validate
 * @returns Returns error message if invalid, undefined otherwise
 */
function getPhoneError(value) {
  if (getRequiredError(value) || getInvalidPhoneError(value)) {
    return 'Please provide a valid phone number';
  }
}

/**
 * Validates an organization selection by checking if it's empty.
 * Only performs required field validation.
 *
 * @param  value - The organization selection to validate
 * @returns  Returns error message if invalid, undefined otherwise
 */
function getOrgError(value) {
  if (getRequiredError(value)) {
    return 'Please select your organization size';
  }
}

export { getEmailError, getInvalidPhoneError, getNameError, getOrgError, getPhoneError, getRequiredError };
