import { event } from '@zillow/universal-analytics';

/**
 * Sends an event with the specified trigger source, label, URL, and CMS information.
 *
 * @param {string} triggerSource - The source of the trigger.
 * @param {string} label - The label for the event.
 * @param {string} url - The URL related to the event.
 * @param {Object} cmsInfo - Additional CMS information for the event.
 */
function sendEvent(triggerSource, label, url, cmsInfo) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2048
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '3',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: triggerSource,
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_click'
    },
    cms_info: Object.assign({
      block_type_cd: 'MainNav',
      block_displayed_txt: label,
      block_target_url: url,
      block_target_window_txt: ''
    }, cmsInfo)
  });
}

export { sendEvent };
