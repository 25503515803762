import { slicedToArray as _slicedToArray } from '../_virtual/_rollupPluginBabelHelpers.js';
import { useState, useEffect } from 'react';

/**
 * Hook to check if the user is logged in, and if so, what their first and last name, email and cell number are.
 * @param {string} env - The environment to check in. Defaults to 'production'.
 * @returns {{ data: { user: boolean | { firstName: string, lastName: string, email: string, profile: { cellNumber: string } } }, loading: boolean }} - An object with a 'data' property containing the user's data, and a 'loading' property indicating if the data is loading.
 */
var useCurrentUser = function useCurrentUser(env) {
  if (env === void 0) {
    env = 'production';
  }
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    user = _useState2[0],
    setUser = _useState2[1];
  var _useState3 = useState(true),
    _useState4 = _slicedToArray(_useState3, 2),
    loading = _useState4[0],
    setLoading = _useState4[1];
  useEffect(function () {
    var url = env === 'production' ? 'https://www.zillow.com/graphql' : 'https://www.qa.zillow.net/graphql';
    (function () {
      return new Promise(function ($return, $error) {
        var query, urlWithQuery, headers, _response$data, response;
        query = "\n\t\t\tquery {\n\t\t\t\tviewer {\n\t\t\t\t\tfirstName\n\t\t\t\t\tlastName\n                    email\n                    profile {\n                      cellNumber\n                    }\n                }\n\t\t\t}\n\t\t\t";
        urlWithQuery = url + "?query=" + encodeURIComponent(query);
        headers = {
          'Content-Type': 'application/json',
          'x-z-enable-oauth-conversion': 'true'
        };
        //  Allows for login memento to be passed in via env var for test
        if (process.env.LOGIN_MEMENTO) {
          headers['x-z-login-memento'] = process.env.LOGIN_MEMENTO;
        }
        var $Try_1_Post = function () {
          try {
            return $return();
          } catch ($boundEx) {
            return $error($boundEx);
          }
        };
        var $Try_1_Catch = function (error) {
          try {
            setUser(false);
            setLoading(false);
            console.error(error);
            return $Try_1_Post();
          } catch ($boundEx) {
            return $error($boundEx);
          }
        };
        try {
          return Promise.resolve(fetch(urlWithQuery, {
            method: 'GET',
            headers: headers
          }).then(function (res) {
            return res.json();
          }).catch(function () {
            return null;
          })).then(function ($await_2) {
            try {
              response = $await_2;
              setUser((response === null || response === void 0 || (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.viewer) || false);
              setLoading(false);
              return $Try_1_Post();
            } catch ($boundEx) {
              return $Try_1_Catch($boundEx);
            }
          }, $Try_1_Catch);
        } catch (error) {
          $Try_1_Catch(error);
        }
      });
    })();
  }, [env]);
  return {
    data: {
      user: user
    },
    loading: loading
  };
};

export { useCurrentUser };
