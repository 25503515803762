import { useRef } from 'react';

function usePAFormStepperFieldRefs() {
  var nameField = useRef(null);
  var emailField = useRef(null);
  var phoneField = useRef(null);
  var orgField = useRef(null);
  return {
    nameField: nameField,
    emailField: emailField,
    phoneField: phoneField,
    orgField: orgField
  };
}

export { usePAFormStepperFieldRefs };
