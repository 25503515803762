import { BlockList } from '@zillow/cms-blocks';
import { Box } from '@zillow/constellation';
import React from 'react';

const FooterTemplate = ({
    footer,
    relatedArticles,
}: {
    footer?: {
        blocks: string;
    };
    relatedArticles?: React.ReactNode;
}): React.ReactNode => (
    <Box
        sx={{
            margin: '0 auto',
            maxWidth: '$breakpoints.xxl',
        }}
    >
        <Box
            sx={{
                padding: '0 0 $spacing.xl',
                '@media $sm_lte': {
                    padding: '0 $spacing.sm $spacing.xl',
                },
            }}
        >
            {relatedArticles}

            <BlockList blocks={JSON.parse(footer?.blocks || '[]')} />
        </Box>
    </Box>
);

export default FooterTemplate;
