import { Box } from '@zillow/constellation';
import PropTypes from 'prop-types';
import React from 'react';

import FooterTemplate from './FooterTemplate/FooterTemplate';

export const SidebarWrapper = ({
    children,
    sticky = false,
    top = '$spacing.md',
}) => (
    <Box
        sx={{
            ...(sticky
                ? {
                      position: 'sticky',
                      top: top,
                      alignSelf: 'flex-start',
                  }
                : {}),
        }}
    >
        {children}
    </Box>
);

SidebarWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    sticky: PropTypes.bool,
    top: PropTypes.string,
};

const MasterLayout = ({
    children,
    contentWidth = '$breakpoints.xl',
    sidebar,
    stickySidebar,
    stickySidebarTop = undefined,
    gapSpacing = '$spacing.xl',
    footer,
    hero,
    relatedArticles,
}) => (
    <React.Fragment>
        <Box
            sx={{
                margin: '0 auto',
                maxWidth: contentWidth,
                padding: '$spacing.xl $spacing.sm',
            }}
        >
            {hero && (
                <Box
                    sx={{
                        marginBottom: '$spacing.xs',
                        // Tablet is md and lg
                        '@media $md': {
                            marginBottom: '$spacing.md',
                        },
                        // Desktop is xl+
                        '@media $xl': {
                            marginBottom: '$spacing.xl',
                        },
                    }}
                >
                    {hero}
                </Box>
            )}

            <Box
                sx={{
                    '@media $lg': {
                        display: 'flex',
                    },
                }}
            >
                <Box
                    sx={{
                        flex: '1 1 0',
                        minWidth: 0,
                        marginBottom: '$spacing.lg',
                    }}
                >
                    {children}
                </Box>
                {(sidebar || stickySidebar) && (
                    <Box
                        sx={{
                            marginTop: '$spacing.xl',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '300px',
                            '@media $md_lte': {
                                width: '100%',
                                marginTop: 0,
                            },
                            '@media $lg': {
                                marginLeft: gapSpacing,
                                marginRight: 0,
                                marginTop: 0,
                            },
                        }}
                    >
                        {sidebar && <SidebarWrapper>{sidebar}</SidebarWrapper>}
                        {stickySidebar && (
                            <SidebarWrapper sticky top={stickySidebarTop}>
                                {stickySidebar}
                            </SidebarWrapper>
                        )}
                    </Box>
                )}
            </Box>
        </Box>

        {(footer || relatedArticles) && (
            <FooterTemplate footer={footer} relatedArticles={relatedArticles} />
        )}
    </React.Fragment>
);

MasterLayout.propTypes = {
    children: PropTypes.node.isRequired,
    contentWidth: PropTypes.string,
    sidebar: PropTypes.node,
    stickySidebar: PropTypes.node,
    stickySidebarTop: PropTypes.string,
    gapSpacing: PropTypes.string,
    footer: PropTypes.node,
    hero: PropTypes.node,
    relatedArticles: PropTypes.node,
};

export default MasterLayout;
