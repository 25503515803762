import { useBlockEnvironment } from '@zillow/cms-blocks';
import { B2BLayout, PAFormStepperModal } from '@zillow/zrs-cms';
import parse from 'html-react-parser';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { STATICS_CDN } from '../../../server/config';
import GlobalStyle from '../GlobalStyle';
import OptimizelyScripts from '../OptimizelyScripts';
import { PreviewModeAlert } from '../PreviewModeAlert';

import ZPALogo from './zpa-logo.svg';

const baseUrl = 'premier-agent';

const MENU_IDS = {
    WhoWeServe: 1,
    Resources: 2,
};

const MENU = [
    {
        parent: 0,
        id: MENU_IDS.WhoWeServe,
        title: { rendered: 'Who We Serve' },
        url: '#',
        path: '#',
    },
    {
        parent: MENU_IDS.WhoWeServe,
        id: 'agents',
        title: { rendered: 'Agents' },
        url: `/${baseUrl}/real-estate-agent/`,
        path: `/${baseUrl}/real-estate-agent/`,
    },
    {
        parent: MENU_IDS.WhoWeServe,
        id: 'teams',
        title: { rendered: 'Teams' },
        url: `/${baseUrl}/real-estate-team/`,
        path: `/${baseUrl}/real-estate-team/`,
    },
    {
        parent: MENU_IDS.WhoWeServe,
        id: 'brokers',
        title: { rendered: 'Brokers' },
        url: `/${baseUrl}/real-estate-broker/`,
        path: `/${baseUrl}/real-estate-broker/`,
    },
    {
        parent: 0,
        id: MENU_IDS.Resources,
        title: { rendered: 'Resources' },
        url: '#',
        path: '#',
    },
    {
        parent: MENU_IDS.Resources,
        id: 'blog',
        title: { rendered: 'Blog' },
        url: `/${baseUrl}/blog/`,
        path: `/${baseUrl}/blog/`,
    },
    {
        parent: MENU_IDS.Resources,
        id: 'toolkit',
        title: { rendered: 'Free agent tools' },
        url: `/${baseUrl}/toolkit/`,
        path: `/${baseUrl}/toolkit/`,
    },
];

const FOOTER_COLUMNS = [
    {
        title: 'Who we serve',
        description: '',
        links: [
            {
                title: 'Agents',
                url: `/${baseUrl}/real-estate-agent/`,
            },
            {
                title: 'Teams',
                url: `/${baseUrl}/real-estate-team/`,
            },
            {
                title: 'Brokers',
                url: `/${baseUrl}/real-estate-broker/`,
            },
        ],
    },
    {
        title: 'Resources',
        description: '',
        links: [
            {
                title: 'Blog',
                url: `/${baseUrl}/blog/`,
            },
            {
                title: 'FAQ',
                url: `/${baseUrl}/frequently-asked-questions/`,
            },
            {
                title: 'Help',
                url: `/${baseUrl}/need-assistance-partner-support/`,
            },
            {
                title: 'Contact us',
                url: `/${baseUrl}/contact-us/`,
            },
        ],
    },
];

type SubnavItem = {
    id: string;
    title: {
        rendered: string;
    };
    url: string;
    parent: number;
};

type ZPALayoutProps = {
    children: React.ReactNode;
    head?: string;
    preview?: boolean;
    submenu?: {
        items: SubnavItem[];
        meta: {
            title: string;
            url: string;
            search: string;
        };
    };
    isBlog?: boolean;
    header?: { disabled?: boolean; transparent?: boolean };
};

function ZPALayout({
    children,
    head,
    preview,
    submenu,
    header,
}: ZPALayoutProps) {
    const router = useRouter();
    const { environment = 'prod' } = useBlockEnvironment();

    return (
        <>
            <Head>
                {head ? parse(head) : <title>Zillow Premier Agent</title>}

                {/* No index for previews */}
                {preview && <meta name="robots" content="noindex" />}
            </Head>

            <B2BLayout
                router={router}
                signInLink={
                    environment === 'prod'
                        ? 'https://premieragent.zillow.com/crm/agentlogin/'
                        : 'https://premieragent.qa.zillow.net/crm/agentlogin/'
                }
                subnavItems={submenu?.items?.map((item) => ({
                    ...item,
                    title: item.title.rendered,
                }))}
                signInRedirectParam="redirect"
                menu={MENU}
                primaryCTALabel="Contact us"
                primaryCTALink="/premier-agent/contact-us/"
                logo={{
                    ...ZPALogo,
                    src: ZPALogo.src.startsWith('http')
                        ? ZPALogo.src
                        : (STATICS_CDN || '') + ZPALogo.src,
                    width: 200,
                    height: 200,
                    alt: 'Zillow Premier Agent',
                    link: `/${baseUrl}/`,
                }}
                footerColumns={FOOTER_COLUMNS}
                subnavPrimaryItem={
                    submenu?.meta?.title
                        ? {
                              title: submenu?.meta?.title,
                              url: submenu?.meta?.url,
                          }
                        : undefined
                }
                searchPrefix={submenu?.meta?.search}
                transparent={header?.transparent}
                disabled={header?.disabled}
                sticky
            >
                <GlobalStyle />
                <OptimizelyScripts />

                {children}

                <PAFormStepperModal />

                {preview && <PreviewModeAlert />}
            </B2BLayout>
        </>
    );
}

export default ZPALayout;
