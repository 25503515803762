import { event } from '@zillow/universal-analytics';

// Mapping these to the expected values in CS.
var STEP_MAP = {
  welcome: 'step_1',
  'agent-email': 'step_2',
  'agent-phone': 'step_3',
  'agent-org': 'step_4',
  'agent-thank-you': 'step_5_confirmation'
};
var tileMap = {
  homer: 'buyer_seller_ctas',
  other: 'other_professional_ctas'
};
function trackBack(currentStep, targetStep) {
  var newLaneData = {
    // https://dataportal.zillowgroup.net/event-registry/2048
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '4',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_click'
    },
    cms_info: {
      block_type_cd: 'anchor',
      block_id: STEP_MAP[currentStep] + '_back',
      block_displayed_txt: 'Back',
      block_target_url: STEP_MAP[targetStep],
      pa_multistep_step_txt: STEP_MAP[targetStep]
    }
  };
  event(newLaneData);
}
function trackContinue(currentStep, targetStep) {
  var newLaneData = {
    // https://dataportal.zillowgroup.net/event-registry/2048
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '4',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_click'
    },
    cms_info: {
      block_type_cd: 'anchor',
      block_id: (STEP_MAP === null || STEP_MAP === void 0 ? void 0 : STEP_MAP[currentStep]) + '_continue',
      block_displayed_txt: 'Continue',
      block_target_url: STEP_MAP === null || STEP_MAP === void 0 ? void 0 : STEP_MAP[targetStep],
      pa_multistep_step_txt: STEP_MAP === null || STEP_MAP === void 0 ? void 0 : STEP_MAP[currentStep]
    }
  };
  event(newLaneData);
}
function trackTileLink(tile, href) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2272
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '4',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_click'
    },
    cms_info: {
      block_type_cd: 'anchor',
      block_id: 'confirmation_ctas',
      block_displayed_txt: tile,
      block_target_url: href,
      pa_multistep_step_txt: 'step_5_confirmation'
    }
  });
}
function trackTileImpression(tile) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2048
    envelope: {
      event_type_id: '2272',
      event_type_version_id: '2',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'impression',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'view_content'
    },
    cms_info: {
      block_type_cd: 'pa_multistep_panel',
      block_id: tileMap[tile],
      pa_multistep_step_txt: tileMap[tile]
    }
  });
}
function trackTileSelect(tile) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2048
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '4',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_click'
    },
    cms_info: {
      block_type_cd: 'anchor',
      block_id: 'tell_us_who_you_are',
      block_displayed_txt: tile,
      pa_multistep_step_txt: 'step_1'
    }
  });
}
function trackStepImpression(step) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2272
    envelope: {
      event_type_id: '2272',
      event_type_version_id: '2',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'impression',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'view_content'
    },
    cms_info: {
      block_type_cd: 'pa_multistep_panel',
      block_id: (STEP_MAP === null || STEP_MAP === void 0 ? void 0 : STEP_MAP[step]) || '',
      pa_multistep_step_txt: (STEP_MAP === null || STEP_MAP === void 0 ? void 0 : STEP_MAP[step]) || ''
    }
  });
}
function trackArrowButtonClick(link, tile) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2272
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '4',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_click'
    },
    cms_info: {
      block_type_cd: 'anchor',
      block_id: tileMap[tile],
      block_displayed_txt: link.label,
      block_target_url: link.href,
      pa_multistep_step_txt: tileMap[tile]
    }
  });
}
function trackInputChange(input, label, step) {
  event({
    // https://dataportal.zillowgroup.net/event-registry/2272
    envelope: {
      event_type_id: '2056',
      event_type_version_id: '3',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_pa_multistep_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: {
      block_type_cd: 'form_field',
      block_id: input,
      block_displayed_txt: label,
      pa_multistep_step_txt: (STEP_MAP === null || STEP_MAP === void 0 ? void 0 : STEP_MAP[step]) || ''
    }
  });
}
function trackModalDialog(step, open) {
  if (open) {
    event({
      // https://dataportal.zillowgroup.net/event-registry/5030
      envelope: {
        event_type_id: '5030',
        event_type_version_id: '1',
        event_template_id: '297',
        event_template_version_id: '1'
      },
      clickstream_trigger: {
        trigger_type_nm: 'interaction',
        trigger_location_nm: 'cms',
        trigger_source_nm: 'cms_pa_multistep_form',
        trigger_object_nm: 'no_trigger_object'
      },
      semantic: {
        semantic_event_nm: 'cms_open'
      },
      cms_info: {
        block_type_cd: 'modal_dialog',
        block_id: 'pa_multistep_form'
      }
    });
  } else {
    event({
      // https://dataportal.zillowgroup.net/event-registry/2048
      envelope: {
        event_type_id: '2048',
        event_type_version_id: '4',
        event_template_id: '126',
        event_template_version_id: '1'
      },
      clickstream_trigger: {
        trigger_type_nm: 'interaction',
        trigger_location_nm: 'cms',
        trigger_source_nm: 'cms_pa_multistep_form',
        trigger_object_nm: 'no_trigger_object'
      },
      semantic: {
        semantic_event_nm: 'cms_click'
      },
      cms_info: {
        block_type_cd: 'modal_dialog',
        block_id: 'pa_multistep_form',
        pa_multistep_step_txt: STEP_MAP[step]
      }
    });
  }
}

export { trackArrowButtonClick, trackBack, trackContinue, trackInputChange, trackModalDialog, trackStepImpression, trackTileImpression, trackTileLink, trackTileSelect };
